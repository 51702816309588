import TYPES from '@/types';
import Vue from 'vue';

import { v4 } from 'uuid';

// Application
import AccessMoreStrategiesStateManagerService
  from '@/modules/my-investment/allianz/access-more-strategies/application/services/access-more-strategies-state-manager-service';
import GetInvestorProfileQuery
  from '@/modules/flagship/investor-profile/investor-profile/application/queries/get-investor-profile-query';
import GetCustomGoalTypesQuery
  from '@/modules/flagship/catalogs/custom-goal-types/application/queries/get-custom-goal-types-query';
import SearchInvestmentTypeHiringStepsQuery
  from '@/modules/my-investment/allianz/investment-type-hiring-step/application/queries/search-investment-type-hiring-steps-query';
import CreateInvestmentTypeHiringStepCommand
  from '@/modules/my-investment/allianz/investment-type-hiring-step/application/commands/create-investment-type-hiring-step-command';
import UpdateInvestmentTypeHiringStepCommand
  from '@/modules/my-investment/allianz/investment-type-hiring-step/application/commands/update-investment-type-hiring-step-command';
import CreateInvestmentFundHireCommand
  from '@/modules/my-investment/allianz/access-more-strategies/application/commands/create-investment-fund-hire-command';

// Domain
import {
  InvestmentTypeHiringStepEntity,
} from '@/modules/my-investment/allianz/investment-type-hiring-step/domain/entities/investment-type-hiring-step-entity';
import AccessMoreStrategiesState from '@/modules/my-investment/allianz/access-more-strategies/domain/state/access-more-strategies-state';
import { DatetimeValue } from '@/modules/shared/domain/value-objects/datetime-value';
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';

export default class AccessMoreStrategiesViewModel {
  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  @Inject(TYPES.ACCESS_MORE_STRATEGIES_STATE_MANAGER_SERVICE)
  private readonly manager_service!: AccessMoreStrategiesStateManagerService;

  @Inject(TYPES.GET_INVESTOR_PROFILE_QUERY)
  private readonly get_investor_profile_query!: GetInvestorProfileQuery;

  @Inject(TYPES.GET_CUSTOM_GOAL_TYPES_QUERY)
  private readonly get_custom_goal_types!: GetCustomGoalTypesQuery;

  @Inject(TYPES.SEARCH_INVESTMENT_TYPE_HIRING_STEP_QUERY)
  private readonly search_investment_type_hiring_steps!: SearchInvestmentTypeHiringStepsQuery;

  @Inject(TYPES.CREATE_INVESTMENT_TYPE_HIRING_STEP_COMMAND)
  private readonly create_investment_type_hiring_step!: CreateInvestmentTypeHiringStepCommand;

  @Inject(TYPES.UPDATE_INVESTMENT_TYPE_HIRING_STEP__COMMAND)
  private readonly update_investment_type_hiring_step!: UpdateInvestmentTypeHiringStepCommand;

  @Inject(TYPES.CREATE_INVESTMENT_FUND_HIRE_COMMAND)
  private readonly create_investment_fund_hire_command!: CreateInvestmentFundHireCommand;

  @Inject(TYPES.DATETIME_VALUE)
  readonly datetimeValue!: DatetimeValue;

  readonly i18n_namespace = 'components.allianz-dashboard.access-more-strategies';

  is_open = false;

  current_step = 0;

  state: AccessMoreStrategiesState;

  stored_steps: Array<InvestmentTypeHiringStepEntity> = [];

  step_id = v4();

  investor_goal_id = v4();

  customer_id = '';

  investor_profile_id = '';

  show_loader = false;

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  steps = [
    {
      header: this.translate('steps.dependents'),
      component: 'FlagshipProfilingStepEconomicDependents',
      visible: true,
      name: 'more_strategies_economic_dependents',
    },
    {
      header: this.translate('steps.employment_situation'),
      component: 'FlagshipProfilingStepEmploymentSituation',
      visible: true,
      name: 'more_strategies_employment_situation',
    },
    {
      header: this.translate('steps.budget'),
      component: 'AccessMoreStrategiesFinancialHabits',
      visible: true,
      name: 'more_strategies_financial_habits',
    },
    {
      header: this.translate('steps.term'),
      component: 'DefineInvestmentTerm',
      visible: true,
      name: 'more_strategies_investment_term',
    },
    {
      header: this.translate('steps.risk_tolerance'),
      component: 'AccessMoreStrategiesRiskTolerance',
      visible: true,
      name: 'more_strategies_risk_tolerance',
    },
    {
      header: this.translate('steps.strategy_result'),
      component: 'InvestmentStrategyResult',
      visible: true,
      name: 'more_strategies_investment_strategy_result',
    },
    {
      header: this.translate('steps.initial_balance'),
      component: 'InvestmentStrategyInitialBalance',
      visible: true,
      name: 'more_strategies_initial_balance',
    },
    {
      header: this.translate('steps.set_up_your_contributions'),
      component: 'InvestmentStrategyConfigureContribution',
      visible: true,
      name: 'more_strategies_contribution_configuration',
    },
    {
      header: this.translate('steps.program_your_contributions'),
      component: 'InvestmentStrategySchedulePayment',
      visible: true,
      name: 'more_strategies_schedule_contributions',
    },
  ];

  current_component = this.steps[this.current_step];

  private readonly view!: Vue;

  public constructor(view: Vue) {
    this.state = this.manager_service.getAccessMoreStrategiesState();
    this.view = view;
  }

  setCurrentComponent = (step: number) => {
    this.step_id = v4();
    this.current_component = this.steps[step];
  }

  nextStep = async (payload: Record<string, any> = {}) => {
    const saved = await this.saveStepInformation(
      this.steps[this.current_step].name,
      payload,
    );
    if (saved) {
      if (this.current_step < this.steps.length) {
        this.current_step += 1;
        this.setCurrentComponent(this.current_step);
      }
    }
  }

  prevStep = async () => {
    if (this.current_step > 0) {
      if (this.current_step === 5 && this.state.strategy.label === 'SWSRFP') {
        this.current_step -= 1;
      }
      this.current_step -= 1;
      this.setCurrentComponent(this.current_step);
    }
  }

  openDefineGoalsFlow = () => {
    this.view.$emit('openDefineGoalsFlow');
  }

  loadingStep = (is_loading: boolean) => {
    this.state.is_loading = is_loading;
  }

  getActiveClass = (index: number) => {
    const inactive_class = 'dark-grey';
    const active_class = 'secondary';
    if (index <= this.current_step) {
      return active_class;
    }

    return inactive_class;
  }

  loadInvestorProfile = async () => {
    const investor_profile = await this.get_investor_profile_query.execute(false);
    this.customer_id = investor_profile.customer_id;
    this.investor_profile_id = investor_profile.id;
  }

  endProcess = async () => {
    try {
      this.state.is_loading = true;
      this.show_loader = true;
      if (!this.state.define_contribution_later) {
        await this.saveStepInformation('more_strategies_recurring_contribution', {
          recurring_contribution: this.state.recurring_contribution,
          goal_contribution: this.state.goal_contribution.toString(),
        });
      }
      return true;
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.complete_flow'));
      return false;
    } finally {
      this.state.is_loading = false;
      this.show_loader = false;
    }
  }

  moveToStoredStep = (stored_steps: Array<InvestmentTypeHiringStepEntity>) => {
    if (stored_steps.length > 0) {
      const last_step = stored_steps[0];
      const step_index = this.steps.findIndex(
        (investment_type_hiring_step) => (
          investment_type_hiring_step.name === last_step.current_step
        ),
      );
      if (step_index > -1) {
        if (step_index < this.steps.length - 2) {
          this.current_step = step_index + 1;
        } else {
          this.current_step = step_index;
        }

        this.setCurrentComponent(this.current_step);
      }
    }
  }

  setState = (stored_steps: Array<InvestmentTypeHiringStepEntity>) => {
    stored_steps.forEach((investment_fund_hiring_step) => {
      if (investment_fund_hiring_step.current_step === this.steps[3].name) {
        this.state.investor_goal.term = investment_fund_hiring_step.payload.term;
        this.state.strategy = investment_fund_hiring_step.payload.strategy;
      }
      if (investment_fund_hiring_step.current_step === this.steps[4].name) {
        this.state.answers_dto = investment_fund_hiring_step.payload.answers_dto;
        this.state.user_selected_a_warning_answer = investment_fund_hiring_step.payload
          .user_selected_a_warning_answer;
        this.state.risk_tolerance_step_id = investment_fund_hiring_step.id;
      }
      if (investment_fund_hiring_step.current_step === this.steps[5].name) {
        this.state.customer_agreement = investment_fund_hiring_step.payload.customer_agreement;
      }
      if (investment_fund_hiring_step.current_step === this.steps[6].name) {
        this.state.initial_balance = investment_fund_hiring_step.payload.initial_balance;
      }
    });
  }

  loadSteps = async () => {
    try {
      const sort_by = {
        sort_field: 'created_at',
        sort_direction: 'asc',
      };
      this.stored_steps = await this.search_investment_type_hiring_steps
        .execute(sort_by);
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.load_steps'));
    }
  }

  validateStepIsStored = (step_name: string): InvestmentTypeHiringStepEntity | undefined => (
    this.stored_steps.find(
      (investment_type_hiring_step) => (
        investment_type_hiring_step.current_step === step_name
      ),
    )
  )

  saveStepInformation = async (step_name: string, payload: Record<string, any>) => {
    try {
      this.state.is_loading = true;
      await this.loadSteps();
      const step_stored = this.validateStepIsStored(step_name);
      if (step_stored) {
        if (Object.keys(payload).length > 0) {
          // eslint-disable-next-line no-param-reassign
          const step_stored_updated = { ...step_stored };
          step_stored_updated.payload = payload;
          await this.update_investment_type_hiring_step.execute(step_stored_updated);
        }
      } else {
        const new_step = {
          id: this.step_id,
          current_step: this.steps[this.current_step].name,
          payload,
        };
        await this.create_investment_type_hiring_step.execute(new_step);
      }
      return true;
    } catch (error) {
      this.message_notifier.showErrorNotification(this.translate('errors.save_step'));
      return false;
    } finally {
      this.state.is_loading = false;
    }
  }

  initialize = async () => {
    this.state.is_loading = true;
    await this.loadSteps();
    await this.loadInvestorProfile();
    this.setState(this.stored_steps);
    this.moveToStoredStep(this.stored_steps);
    this.state.is_loading = false;
  }
}
